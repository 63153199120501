import {QueryParameters, Response} from 'algoliasearch'
import {AlgoliaRecord} from '../types/bikini'
import {navigate} from 'gatsby'

import React, {useState, useRef, useEffect} from 'react'
import styled, {ThemeProvider} from 'styled-components'

import Event from '../components/event'
import SEO from '../layout/seo'
import Back from '../images/back'
import Close from '../images/close'

import algolia from '../helpers/algolia'
import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {currentTimestamp, ended} from '../helpers/date'

const MAX_HITS = 10

const Container = styled.div`
  min-height: 100vh;
  background: ${colors.grey};
  padding: 50px 12vw;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px 10px 50px; /* original design was 50px 10px */
  }
`

const SearchBox = styled.div`
  display: flex;
  padding: 20px;
  border-bottom: 2px solid ${colors.white};
  margin-bottom: 50px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0; /* original design was 20px 0 10px */
    margin-bottom: 20px; /* original design was 30px */
  }
`

const Button = styled.button`
  padding: 20px;
  svg {
    height: 35px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 20px 0;
    svg {
      height: 27px;
    }
  }
`

interface Results {
  future: AlgoliaRecord[]
  past: AlgoliaRecord[]
}

// rework for <910px wide
const TextField = styled.input`
  flex: 1;
  width: 100%;
  color: ${colors.white};
  background: transparent;
  border: none;
  font-size: 1.9rem;
  margin: 0 30px 3px;
  padding: 10px;
  font-weight: 600;
  ::placeholder {
    color: ${colors.lightGrey};
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 10px;
    font-size: 1.25rem;
  }
`

const Separator = styled.h2`
  padding: 30px 0 20px;
  color: ${colors.white};
`

const getEmptyResults = (): Results => ({
  future: [],
  past: [],
})

const SearchPage = () => {
  const [text, setText] = useState('')
  const [results, setResults] = useState<Results>(getEmptyResults)
  const inputRef = useRef<HTMLInputElement>()

  const search = (query: string) => {
    if (query) {
      const params: QueryParameters = {
        query,
        hitsPerPage: MAX_HITS,
      }
      algolia.search(params, handleResults)
    } else {
      setResults(getEmptyResults())
    }
  }

  const handleBack = () => {
    if (history.length > 2) {
      history.back()
    } else {
      navigate('/')
    }
  }

  useEffect(() => {
    inputRef.current.focus()

    const handleKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleBack()
      }
    }
    document.addEventListener('keydown', handleKey, {passive: true})
    return () => document.removeEventListener('keydown', handleKey)
  }, [])

  const handleInput = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    setText(value)
    search(value)
  }

  const handleResults = (error: Error, data: Response) => {
    if (error) {
      throw error
    }
    const now = currentTimestamp()
    const newResults: Results = getEmptyResults()
    data.hits.forEach((record: AlgoliaRecord) => {
      if (record.unveilDate > now) {
        // hide from results for now
        return // TODO: live unveil
      }
      if (ended(record)) {
        newResults.past.push(record)
      } else {
        newResults.future.push(record)
      }
    })
    setResults(newResults)
  }

  const handleClear = () => {
    setText('')
    setResults(getEmptyResults())
    inputRef.current.focus()
  }

  return (
    <Container>
      <SEO noindex />
      <SearchBox>
        <Button onClick={handleBack} aria-label="Retour">
          <Back />
        </Button>
        <TextField
          value={text}
          onChange={handleInput}
          placeholder="Rechercher un événement"
          ref={inputRef}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          aria-label="Rechercher un événement"
        />
        {text && (
          <Button onClick={handleClear}>
            <Close />
          </Button>
        )}
      </SearchBox>
      <ThemeProvider theme={{squares: false}}>
        <>
          {results.future.map((record) => (
            <Event key={record.objectID} event={record} />
          ))}
          {results.past.length > 0 && <Separator key="separator">Événements passés :</Separator>}
          {results.past.map((record) => (
            <Event key={record.objectID} event={record} />
          ))}
        </>
      </ThemeProvider>
    </Container>
  )
}

export default SearchPage
