import React, {SVGAttributes} from 'react'

const Back: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={38} height={37} viewBox="0 0 38 37" {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd">
      <path stroke="#FFFEFE" d="M22.29 1.29L2 18.51 22.29 36" />
      <path d="M3 18.65h34" stroke="#FFF" strokeLinecap="square" />
    </g>
  </svg>
)

export default Back
